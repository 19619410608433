import { BlitzLayout } from "@blitzjs/next"
import Head from "next/head"
import React, { ReactNode, Suspense } from "react"
import { Nav } from "../components/Nav"

type LayoutProps = {
  title?: string
  children: ReactNode
}

const Layout: BlitzLayout<{ title?: string; children?: React.ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <>
      <Head>
        <title>{process.env.APP_NAME + " - Do anything"}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Suspense fallback={<></>}>
        <Nav />
      </Suspense>
      <main>
        <div>{children}</div>
      </main>
    </>
  )
}

export default Layout
